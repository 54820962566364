import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="personnel"
export default class extends Controller {
  static targets = [ "isIndividualInput",
                     "quantity",
                     "quantityStartInput",
                     "individualStartInput",
                     "salaryInputType" ]

  connect() {
    this.isIndividualUpdate();
  }

  isIndividualUpdate(event) {
    let isIndividualValue;
    if (event == null) {
      let checkedElement = Array.from(this.isIndividualInputTargets).find(isIndividualInput => isIndividualInput.checked);
      isIndividualValue = checkedElement ? checkedElement.value : null;
    } else {
      isIndividualValue = event.currentTarget.value;
    }

    this.quantityTarget.classList.add("d-none");
    this.individualStartInputTarget.classList.add("d-none");

    switch (isIndividualValue) {
      case 'true':
        this.individualStartInputTarget.classList.remove("d-none");
        break;
      case 'false':
        this.quantityTarget.classList.remove("d-none");
    }
    this.enableStartInput(isIndividualValue);
  }

  enableStartInput(isIndividualValue) {
    let isIndividuaBool = isIndividualValue == 'true';
    this.quantityStartInputTarget.querySelector("select").disabled = isIndividuaBool;
    this.individualStartInputTarget.querySelector("select").disabled = !isIndividuaBool;
  }
}
