import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direct-cost"
export default class extends Controller {
  static targets = [ "saleFields", "longTermFields", "currentFields", "isLongTermInput", "willResellInput" ]

  connect() {
    this.updateLongTermFields();
    this.updateSaleFields();
  }

  updateLongTermFields(event) {
    console.log("in");
    let isLongTermValue;
    if (event == null) {
      let checkedElement = Array.from(this.isLongTermInputTargets).find(isLongTermInput => isLongTermInput.checked);
      isLongTermValue = checkedElement ? checkedElement.value : null;
    } else {
      isLongTermValue = event.currentTarget.value;
    }

    this.longTermFieldsTarget.classList.add("d-none");
    this.currentFieldsTarget.classList.add("d-none");

    if (isLongTermValue == 'true') {
      this.longTermFieldsTarget.classList.remove("d-none");
    } else if (isLongTermValue == 'false') {
      this.currentFieldsTarget.classList.remove("d-none");
    }
  }

  updateSaleFields(event) {
    let willResellValue;
    if (event == null) {
      let checkedElement = Array.from(this.willResellInputTargets).find(willResellInput => willResellInput.checked);
      willResellValue = checkedElement ? checkedElement.value : null;
    } else {
      willResellValue = event.currentTarget.value;
    }

    this.saleFieldsTarget.classList.add("d-none");

    if (willResellValue == 'true') this.saleFieldsTarget.classList.remove("d-none");
  }
}
