import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {
  static targets = [ "list", "item", "new" ]

  connect () {
    super.connect()

    this.listTargets.forEach((list) => {
      new Sortable(list, {
        group: 'sections',
        animation: 150,
        swapThreshold: 0.65,
        onEnd: this.sort.bind(this),
        filter: '.filtered'
      });
    });

    new Sortable(this.newTarget, {
      group: {
          name: 'sections',
          pull: 'clone',
          put: false // Do not allow items to be put into this list
      },
      animation: 150,
      sort: false,
      onEnd: this.sort.bind(this)
    });
  }

  sort(event) {
    let sections =
      this.itemTargets.filter((section) => {
        return section.parentElement.dataset.chapterId == event.item.parentElement.dataset.chapterId
      }).map((section, index) => {
        return {
          id: section.dataset.sectionId,
          order: index,
          chapter_id: section.parentElement.dataset.chapterId
        }
      });

    this.stimulate("SectionsReflex#sort", JSON.stringify(sections));
  }

}
