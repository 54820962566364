import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-type"
export default class extends Controller {
  static targets = [ "typeInput",
                     "constant",
                     "varying",
                     "noFee",
                     "revenuePercentage",
                     "overallRevenuePercentage",
                     "revenueStreamPercentage",
                     "oneTimeAmount",
                     "monthlyValue" ]

  connect() {
    this.updateInputs();
  }

  updateInputs(event) {
    let inputType;
    if (event == null) {
      let checkedElement = Array.from(this.typeInputTargets).find(typeInput => typeInput.checked);
      inputType = checkedElement ? checkedElement.value : null;
    } else {
      inputType = event.currentTarget.value;
    }

    this.hide(this.constantTargets);
    this.hide(this.varyingTargets);
    this.hide(this.noFeeTargets);
    this.hide(this.revenuePercentageTargets);
    this.hide(this.overallRevenuePercentageTargets);
    this.hide(this.revenueStreamPercentageTargets);
    this.hide(this.oneTimeAmountTargets);

    switch (inputType) {
      case 'constant':
        this.show(this.constantTargets);
        break;
      case 'varying':
        this.show(this.varyingTargets);
        break;
      case 'no_fee':
        this.show(this.noFeeTargets);
        break;
      case 'revenue_percentage':
        this.show(this.revenuePercentageTargets);
        break;
      case 'overall_revenue_percentage':
        this.show(this.overallRevenuePercentageTargets);
        break;
      case 'revenue_stream_percentage':
        this.show(this.revenueStreamPercentageTargets);
        break;
      case 'one_time_amount':
        this.show(this.oneTimeAmountTargets);
    }
  }

  show(elements) {
    elements.forEach(element => {
      element.classList.remove('d-none');
    });
  }

  hide(elements) {
    elements.forEach(element => {
      element.classList.add('d-none');
    });
  }

  updateVaryingFields(event) {
    Array.from(this.monthlyValueTargets)
      .filter(input => input.dataset.yearIndex == event.currentTarget.dataset.yearIndex)
      .forEach(input => this.updateNextYearInput(input, event.currentTarget.value))
  }

  updateNextYearInput(input, growthRate) {
    growthRate = parseInt(growthRate);

    if (isNaN(growthRate)) return;

    let nextYearInput = Array.from(this.monthlyValueTargets)
      .find(other => other.dataset.yearIndex == parseInt(input.dataset.yearIndex) + 1 && other.dataset.monthIndex == input.dataset.monthIndex);

    nextYearInput.value = Math.round(parseFloat(input.value) * (100 + growthRate) / 100.0);
  }
}
