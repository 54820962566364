import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "checkbox" ]

  connect() {

  }

  uncheckAll(event) {
    let checkboxes = this.checkboxTargets
    if (event.currentTarget.name == "section_ids[]") {
      checkboxes = checkboxes.filter((checkbox) => checkbox.dataset.sectionId == event.currentTarget.value);
    }

    checkboxes.forEach((checkbox) => {
      checkbox.disabled = !event.currentTarget.checked;
    });
  }
}
