import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direct-cost"
export default class extends Controller {
  static targets = [ "typeInput", "startInput", "revenueStreamSelect", "revenueUnit" ]

  connect() {
    this.updateForm();
    this.updateLabel();
  }

  updateForm(event) {
    let directCostType;
    if (event == null) {
      let checkedElement = Array.from(this.typeInputTargets).find(typeInput => typeInput.checked);
      directCostType = checkedElement ? checkedElement.value : null;
    } else {
      directCostType = event.currentTarget.value;
    }

    this.displayInputs(directCostType);
  }

  displayInputs(directCostType) {
    this.startInputTarget.classList.add("d-none");
    this.revenueUnitTarget.classList.add('d-none');

    switch (directCostType) {
      case 'constant':
      case 'revenue_percentage':
        this.startInputTarget.classList.remove('d-none');
        break;
      case 'revenue_unit':
        this.revenueUnitTarget.classList.remove('d-none');
    }
  }

  updateLabel() {
    let labelElement = document.querySelector('label[for="direct_cost_input_type_revenue_percentage"]');
    let newText = ((this.revenueStreamSelectTarget.value == '') ? I18n.dictionary.overall_revenue_percentage : I18n.dictionary.revenue_stream_percentage)

    const inputNode = labelElement.childNodes[0];
    labelElement.innerHTML = '';
    labelElement.appendChild(inputNode);
    labelElement.appendChild(document.createTextNode(newText));
  }
}
