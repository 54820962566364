import ApplicationController from './application_controller'

import Sortable from "sortablejs";

export default class extends ApplicationController {
  static targets = [ "list", "item" ]

  connect () {
    super.connect()

    this.listTargets.forEach((list) => {
      new Sortable(list, {
        animation: 150,
        swapThreshold: 0.65,
        onEnd: this.sort.bind(this),
        filter: '.filtered'
      })
    });
  }

  sort() {
    let forecast_elements =
      this.itemTargets.map((forecast_element, index) => {
        return {
          id: forecast_element.dataset.forecastElementId,
          order: (index + 1),
          model_name: forecast_element.dataset.modelName
        }
      });

    this.stimulate("ForecastElementsReflex#sort", JSON.stringify(forecast_elements));
  }
}
