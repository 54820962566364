import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="revenue-stream"
export default class extends Controller {
  static targets = [ "typeInput",
                     "quantity",
                     "quantityHeading",
                     "revenue",
                     "price",
                     "priceHeading",
                     "upfrontFee",
                     "churnRate",
                     "quantityStartInput",
                     "revenueStartInput" ]

  connect() {
    this.updateForm();
  }

  updateForm(event) {
    let revenueStreamType;
    if (event == null) {
      let checkedElement = Array.from(this.typeInputTargets).find(typeInput => typeInput.checked);
      revenueStreamType = checkedElement ? checkedElement.value : null;
    } else {
      revenueStreamType = event.currentTarget.value;
    }

    this.displayInputs(revenueStreamType);
    this.updateHeadings(revenueStreamType);
    this.enableStartInput(revenueStreamType);
  }

  displayInputs(revenueStreamType) {
    this.quantityTarget.classList.add("d-none");
    this.priceTarget.classList.add("d-none");
    this.churnRateTarget.classList.add("d-none");
    this.upfrontFeeTarget.classList.add("d-none");
    this.revenueTarget.classList.add("d-none");

    switch (revenueStreamType) {
      case 'unit_sales':
        this.quantityTarget.classList.remove("d-none");
        this.priceTarget.classList.remove("d-none");
        break;
      case 'billable_hours':
        this.quantityTarget.classList.remove("d-none");
        this.priceTarget.classList.remove("d-none");
        break;
      case 'recurring_charges':
        this.quantityTarget.classList.remove("d-none");
        this.priceTarget.classList.remove("d-none");
        this.churnRateTarget.classList.remove("d-none");
        this.upfrontFeeTarget.classList.remove("d-none");
        break;
      case 'revenue_only':
        this.revenueTarget.classList.remove("d-none");
    }
  }

  updateHeadings(revenueStreamType) {
    switch (revenueStreamType) {
      case 'unit_sales':
        this.quantityHeadingTarget.textContent = I18n.revenue_streams.form.revenue_stream_type.unit_sales.quantity_label;
        this.priceHeadingTarget.textContent = I18n.revenue_streams.form.titles.unit_prices;
        break;
      case 'billable_hours':
        this.quantityHeadingTarget.textContent = I18n.revenue_streams.form.revenue_stream_type.billable_hours.quantity_label;
        this.priceHeadingTarget.textContent = I18n.revenue_streams.form.titles.hourly_rate;
        break;
      case 'recurring_charges':
        this.quantityHeadingTarget.textContent = I18n.revenue_streams.form.titles.signups;
        this.priceHeadingTarget.textContent = I18n.revenue_streams.form.titles.charges;
    }
  }

  enableStartInput(revenueStreamType) {
    this.quantityStartInputTarget.disabled = revenueStreamType == 'revenue_only';
    this.revenueStartInputTarget.disabled = !this.quantityStartInputTarget.disabled;
  }
}
